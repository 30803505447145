import ajax from '../assets/uilt/ajax';

let remunerationStatistics = {
  /**渠道机构、平台查询酬金明细 */
  remunerationDetailed: req => { return ajax.get({ url: "/back/remuneration-statistics/remuneration-detailed", param: req.param, success: req.success }) },

  /**渠道机构、平台查询酬金统计Excel导出 */
  remunerationDetailedExport: "/back/remuneration-statistics/remuneration-detailed-export",


  /**渠道机构、平台查询酬金明细 */
  assessRemittanceRank: req => { return ajax.get({ url: "/back/remuneration-statistics/assess-remittance-rank", param: req.param, success: req.success }) },
}

export default remunerationStatistics;