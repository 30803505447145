<template>
  <el-card class="box-card full-screen">
    <div ref="pageHead" class="table-page-head">
      <el-form :inline="true" :model="queryForm">
        <!-- <el-form-item :label="$t('orgName')">
          <el-input v-model="queryForm.orgName" size="small" :placeholder="$t('orgName')"></el-input>
        </el-form-item> -->
        <el-form-item :label="$t('date')">
          <el-date-picker v-model="queryForm.date" type="daterange" range-separator="~" :start-placeholder="$t('startDate')" :end-placeholder="$t('endDate')" value-format="yyyy-MM-dd" size="small" unlink-panels :clearable="false" :picker-options="pickerOptions0" @change='changeDate'>
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item :label="$t('accountType')">
          <el-select v-model="queryForm.orgCumType" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('The remittance amount')" value="3"></el-option>
            <el-option :label="$t('withdrawalAmount')" value="1"></el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item :label="$t('accountType')">
          <el-select v-model="queryForm.operType" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('directSaleStore')" value="2"></el-option>
            <el-option :label="$t('allianceBusiness')" value="3"></el-option>
          </el-select>
        </el-form-item>
        <!-- 区域选择器 -->
        <el-form-item :label="$t('region')">
          <el-select v-model="queryForm.region" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <template v-for="(item,index) in countryList">
              <el-option-group v-if="item.parentId==0" :key="index" :label="item.name">
                <template v-for="(it,i) in countryList">
                  <el-option v-if="it.parentId==item.id" :key="i+index.toString()" :label="it.name" :value="it.id"></el-option>
                </template>
              </el-option-group>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getList" :loading="isLoading"><i class="el-icon-search"></i> {{$t('query')}}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}">
      <el-table :data="tableData.records" v-loading="isLoading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%">
        <el-table-column prop="rank" fixed="left" :label="$t('ranking')" width="80" align="center">
          <template slot-scope="scope">TOP {{scope.row.rank}}</template>
        </el-table-column>
        <el-table-column prop="orgname" fixed="left" :label="$t('orgName')"> </el-table-column>
        <el-table-column prop="remittancenum" fixed="left" :label="$t('remittanceAmount')" sortable>
          <template slot-scope="scope">₣ {{scope.row.remittancenum.formatMoney(0)}}</template>
        </el-table-column>
        <el-table-column prop="withdrawalnum" fixed="left" :label="$t('withdrawalAmount')" sortable>
          <template slot-scope="scope">₣ {{scope.row.withdrawalnum.formatMoney(0)}}</template>
        </el-table-column>
      </el-table>
    </div>

    <!--  <el-pagination background layout="total,sizes,prev,pager,next,jumper" :total="tableData.total||0" :page-sizes="[20, 30, 50, 100, 200]" :page-size="pageSize" :current-page="currentPage" @size-change="setPageSzie" @current-change="setCurrentChange" @prev-click="setCurrentChange" @next-click="setCurrentChange" /> -->
  </el-card>
</template>

<script>
import remunerationStatistics from "@/api/remunerationStatistics"
export default {
  data () {
    return {
      pickerOptions0: {
        onPick: ({ maxDate, minDate }) => {
          this.minDate = minDate;
          this.maxDate = maxDate;
        },
        disabledDate: (time) => {
          let today = 3 * 30 * 24 * 3600 * 1000;
          if (this.minDate) {
            return time.getTime() > Date.now() || time > new Date(this.minDate.getTime() + today) || time < new Date(this.minDate.getTime() - today)
          }
          return time.getTime() > Date.now();
        },
      },


      queryForm: { tab: 0 },
      //table高度，动态设置
      queryTableHeight: String,

      isLoading: false,
      tableData: {},

      //分页器默认参数
      pageSize: 20,
      currentPage: 1,
    }
  },
  computed: { countryList () { return this.$store.state.globalParameter.countryList }, },
  methods: {
    setPageSzie (val) {
      this.pageSize = val;
      this.getList();
    },
    setCurrentChange (val) {
      this.currentPage = val;
      this.getList();
    },
    /**查询列表 */
    getList () {
      let _this = this;

      let params = JSON.parse(JSON.stringify(_this.queryForm))
      if (params.date && params.date.length > 0) {
        params.startDate = _this.$manba(params.date[0]).format('ymd');
        params.endDate = _this.$manba(params.date[1]).format('ymd');
      }
      else {
        params.startDate = undefined;
        params.endDate = undefined;
      }
      delete params.date;

      _this.isLoading = true;
      remunerationStatistics.assessRemittanceRank({
        // param: { current: _this.currentPage, size: _this.pageSize, ...params },
        param: { current: 1, size: -1, ...params },
        success: res => {
          _this.tableData = res.data || {};
          _this.isLoading = false;
        }
      })
    },

    changeDate (e) {
      if (e === null) {
        this.minDate = "";
        this.maxDate = "";
        this.pickerBeginDateBefore = {
          disabledDate: (time) => {
            let three = 3 * 30 * 24 * 3600 * 1000;
            if (this.minDate) {
              return time.getTime() > Date.now() || time > new Date(this.minDate.getTime() + three) || time < new Date(this.minDate.getTime() - three)
            }
            return time.getTime() > Date.now();
          },
        };
      }
    },
  },
  mounted () {
    this.$nextTick(() => {
      let queryTableHeight = this.$refs.pageHead.offsetHeight + 15;
      this.queryTableHeight = queryTableHeight + 'px';
      this.getList();
    })
  }
}
</script>

<style lang="less" scoped>
</style>